"use client";

import { type VariantProps } from "cva";
import { type HTMLMotionProps, motion, type Variants } from "framer-motion";
import { type HTMLAttributes, type ReactNode } from "react";

import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { useSidebarContext } from "@/contexts/sidebar-context";
import { sidebarAnimationSpeed, sidebarMaximumWidth, sidebarMinimumWidth } from "@/lib/settings";
import { cva, cx } from "@/lib/utils";

export type SidebarProps = HTMLAttributes<HTMLElement> &
  HTMLMotionProps<"div"> &
  VariantProps<typeof variants> & {
    headerNavigation: ReactNode;
    footerNavigation: ReactNode;
  };

const variants = cva({
  base: "no-scrollbar fixed top-0 z-10 hidden h-screen w-full flex-shrink-0 overflow-x-hidden overflow-y-scroll bg-brand-gray-400 supports-[height:100dvh]:h-[100dvh] sm:block lg:sticky",
});

const maximumWidth = sidebarMaximumWidth;
const minimumWidth = sidebarMinimumWidth;
const duration = sidebarAnimationSpeed;

const sidebarAnimationVariants: Variants = {
  open: { width: maximumWidth, transition: { duration, ease: "circInOut" } },
  closed: { width: minimumWidth, transition: { duration, ease: "circInOut" } },
};

export function Sidebar({ headerNavigation, footerNavigation, className, ...props }: SidebarProps) {
  const { isSidebarOpen } = useSidebarContext();

  return (
    <motion.div
      variants={sidebarAnimationVariants}
      initial="open"
      animate={isSidebarOpen ? "open" : "closed"}
      className={cx(variants(), className)}
      {...props}
    >
      <div className="flex min-h-full flex-col justify-between gap-y-2 py-md md:py-lg">
        <Header>{headerNavigation}</Header>
        <Footer>{footerNavigation}</Footer>
      </div>
    </motion.div>
  );
}
